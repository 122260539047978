import BaseService from '@/services/BaseService'

let resourceName = 'log'

class LogService extends BaseService {
}

const logService = new LogService(resourceName)

export default logService
