export default class DepartmentModel {
  constructor (props) {
    if (props) {
      this.lContent = props.lContent
      this.lDate = props.lDate
      this.lInfoName = props.lInfoName
      this.lType = props.lType
      this.lUser = props.lUser
      this.lUserName = props.lUserName
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.tenderGuid = props.tenderGuid
      this.tenderName = props.tenderName
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.lDate) this.lDate = ''
    if (!this.lInfoName) this.lInfoName = ''
    if (!this.lType) this.lType = ''
    if (!this.lUser) this.lUser = ''
    if (!this.lUserName) this.lUserName = ''
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.tenderName) this.tenderName = ''
  }
}
