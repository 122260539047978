<template>
  <div class="log-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="searchModel.beginDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="searchModel.endDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.projectName"
            placeholder="项目名称">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.tenderName"
            placeholder="标段名称">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.lInfoName"
            placeholder="接口名称">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchModel.lType" clearable>
            <el-option
              label="INFO"
              value="INFO"
              >
            </el-option>
            <el-option
              label="Error"
              value="Error"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="logList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column prop="index" width="60" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="projectName" width="250" header-align="center" align="center" label="项目名称"></el-table-column>
      <el-table-column prop="tenderName" width="80" header-align="center" align="center" label="标段名称"></el-table-column>
      <el-table-column prop="lUserName" width="120" header-align="center" align="center" label="用户名"></el-table-column>
      <el-table-column prop="lInfoName" header-align="center" align="center" label="访问接口"></el-table-column>
      <el-table-column prop="lContent" header-align="center" align="center" label="说明"></el-table-column>
      <el-table-column prop="lType" width="100" header-align="center" align="center" label="类型"></el-table-column>
      <el-table-column prop="lDate" width="160" :formatter="timeFormatter" header-align="center" align="center" label="日志时间"></el-table-column>
    </my-el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyElTable from '@/components/MyElTable/MyElTable'
import LogModel from '@/model/LogModel'
import logService from '@/services/logService'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import moment from 'moment'

export default {
  name: 'LogIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MySearchBar,
    MyElTable
  },
  data () {
    return {
      total: 0,
      logList: [],
      logModel: new LogModel(),
      formDialogTitle: '',
      searchModel: {
        beginDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).add('days', 1).format('YYYY-MM-DD'),
        tenderName: '',
        projectName: '',
        lInfoName: '',
        lType: ''
      }
    }
  },
  methods: {
    handleSearchClick () {
      this._getLogList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加部门信息'
      this.logModel = new LogModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑部门信息'
      this.logModel = new LogModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _logModel = new LogModel(row)
          logService.delete(_logModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getLogList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.logForm.validate()(valid => {
        if (valid) {
          if (!this.logModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getLogList () {
      logService.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'Logs.Ldate desc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.logList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      logService.add(this.logModel)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getLogList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      logService.edit(this.logModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getLogList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getLogList()
    this.refreshMethod = this._getLogList
  }
}
</script>
